import React,{ createContext } from 'react';
export const URLProvider = createContext({});

export const D3URLProvider = (props) => {

 const URLs = {
   //URL_GetindividualUserInfo: 'https://ykxdmuibuk.execute-api.ap-northeast-1.amazonaws.com/d3Point-individualuserinfo/d3point-individualuserinfo',
   URL_GetindividualUserInfo : 'https://ls81te6yb1.execute-api.ap-northeast-3.amazonaws.com/d3Point-individualuserinfo/d3point-individualuserinfo',
   //URL_UpdateIndividualUserInfo: 'https://hosf4yeyhj.execute-api.ap-northeast-1.amazonaws.com/d3Point-IndividualUserInfoUpdate/d3point-individualuserinfoupdate',
   URL_UpdateIndividualUserInfo : ' https://ig115ueig5.execute-api.ap-northeast-3.amazonaws.com/d3Point-IndividualUserInfoUpdat/d3point-individualuserinfoupdate', 
   //URL_GetBushoInfo : 'https://5r2271x8ma.execute-api.ap-northeast-1.amazonaws.com/d3Point-DepartmentInfoList/d3point-departmentinfolist',
   URL_GetBushoInfo : 'https://c5cea0tfqi.execute-api.ap-northeast-3.amazonaws.com/d3Point-DepartmentInfoList/d3point-departmentinfolist',
   //URL_MailHistory : 'https://km1ss1atn9.execute-api.ap-northeast-1.amazonaws.com/d3Point-mailhistory/d3point-mailhistory',
   URL_MailHistory : 'https://019ersmrq6.execute-api.ap-northeast-3.amazonaws.com/d3Point-mailhistory/d3point-mailhistory',
   //URL_SendMail : 'https://tgztfc7o98.execute-api.ap-northeast-1.amazonaws.com/d3Point-sendmail/d3point-sendmail',
   URL_SendMail : ' https://89hw5scog0.execute-api.ap-northeast-3.amazonaws.com/d3Point-SendMailWithPoint',
   //URL_AllUserList : 'https://qyrv18i1yb.execute-api.ap-northeast-1.amazonaws.com/d3Point-alluserlist/d3point-alluserlist',
   URL_AllUserList : 'https://cm1jmg5qvj.execute-api.ap-northeast-3.amazonaws.com/d3Point-AllUserList/d3point-alluserlist',
   //URL_MessageTemplate : 'https://5p9mzl8jk0.execute-api.ap-northeast-1.amazonaws.com/d3Point-MessageTemplate/d3point-messagetemplate',
   URL_MessageTemplate : 'https://fu61kvd0u2.execute-api.ap-northeast-3.amazonaws.com/d3Point-MessageTemplate/d3point-messagetemplate',
   
   URL_GetKatsudoMei :'https://1gv8e1dabd.execute-api.ap-northeast-3.amazonaws.com/d3Point-GetKatsudoMei/d3point-getkatsudomei',
   
   URL_MasterPoint : 'https://96fgalc00b.execute-api.ap-northeast-3.amazonaws.com/d3point-masterPoint',
   //開発
   //URL_PointsHistoryBymenber : 'https://dl7eederxi.execute-api.ap-northeast-1.amazonaws.com/d3Point-PointsHistoryBymenber',
   //大阪R
   URL_PointsHistoryBymenber :'https://3r9c8mm6ll.execute-api.ap-northeast-3.amazonaws.com/d3Point-PointsHistoryBymenber',
   
   //大阪R
   URL_PointGetTotalPoint :'https://y27cv847tf.execute-api.ap-northeast-3.amazonaws.com/d3Point-GetTotalPoint',
   
   //開発
   //URL_CheckAdministrator : 'https://5iky2y901a.execute-api.ap-northeast-1.amazonaws.com/d3Point-CheckAdministrator',
   //大阪R
   URL_CheckAdministrator :'https://u53gh8hto1.execute-api.ap-northeast-3.amazonaws.com/d3Point-CheckAdministrator',
   
   //開発
   //URL_PointsHistoryByAdministrator : 'https://pgku1qz820.execute-api.ap-northeast-1.amazonaws.com/d3Point-PointsHistoryByAdministrator/d3point-resource',
   //大阪R
   URL_PointsHistoryByAdministrator : 'https://stmipuwbc2.execute-api.ap-northeast-3.amazonaws.com/d3Point-PointsHistoryByAdministrator',

   URL_MasterPointsList : 'https://o8qzlvgavk.execute-api.ap-northeast-3.amazonaws.com/stage20230926/ichikawa',
   URL_PointFuyo : 'https://8y7lgyiyv4.execute-api.ap-northeast-3.amazonaws.com/d3point-pointfuyo/d3point-pointfuyo',
   
  
  
 }

 return(
  <URLProvider.Provider value={{URLs}}>
    {props.children}
  </URLProvider.Provider>
 )
}
